.login-form-wrapper {
  height: 100vh;
}

.login-screen-logo {
  width: 20%;
}

@media only screen and (max-width: 992px) {
  .login-form-wrapper {
    padding-top: 150px;
    height: fit-content;
    width: fit-content;
  }
  .login-screen-logo {
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .login-screen-logo {
    width: 60%;
  }
}
