.overview-card {
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.2);
  }
}

.card-edit-icon {
  height: 18px;
  width: 18px;
  &:hover {
    color: green;
    // height: 20px;
    // width: 20px;
  }
}

.card-delete-icon {
  height: 18px;
  width: 18px;
  &:hover {
    color: red;
    // height: 20px;
    // width: 20px;
  }
}
