.navigation-bar {
  padding-left: 0 !important;
  margin-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
}

.logout-button {
  color: red !important;
  &:hover {
    color: black !important;
  }
}

.plansreen-nav-color {
  background-color: rgba(69, 69, 69, 0.7);
  position: absolute;
  top: 0;
  width: 100%;
}
