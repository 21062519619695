.plan-screen-plan-buttons {
  width: 60%;
}

@media only screen and (max-width: 992px) {
  .plan-screen-plan-buttons {
    width: 100%;
  }
  .rating-col {
    margin-top: 50px;
  }
}

//   @media only screen and (max-width: 768px) {
//     .login-screen-logo {
//       width: 80%;
//     }
//   }
