@import url("https://use.typekit.net/ljw7fuf.css");

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
label,
input,
button,
strong,
div {
  font-family: "aller", sans-serif;
}

p {
  font-weight: 300;
}
