.login-wrapper {
  width: 30%;
}

@media only screen and (max-width: 992px) {
  .login-wrapper {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .login-wrapper {
    width: 100%;
  }
}
