.wrapper {
  margin-right: auto; /* 1 */
  margin-left: auto; /* 1 */

  max-width: 1600px; /* 2 */

  padding-right: 10px; /* 3 */
  padding-left: 10px; /* 3 */
}

.app-loader {
  height: 100vh;
}

a {
  color: red;
}

.section-holder-gray {
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #808080;
}

.section-holder {
  padding-top: 100px;
  padding-bottom: 100px;
}

.footer-legal {
  padding-top: 50px;
  text-align: right;
}

.footer-legal-col {
  justify-content: flex-end;
}

.hero {
  min-height: 50vh;
}

.hero-img {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 40%;
}

@media only screen and (max-width: 1024px) {
  .hero {
    min-height: 40vh;
  }
}

@media only screen and (max-width: 992px) {
  .hero-img {
    width: 50%;
  }
  .section-holder-gray {
    padding-top: 100px;
    padding-bottom: 100px;
    -webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out, height 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out, height 300ms ease-in-out;
    transition: width 300ms ease-in-out, height 300ms ease-in-out;
  }
  .footer-col-center {
    justify-content: center;
  }
  .footer-legal {
    padding-top: 50px;
    text-align: center;
  }
  .footer-legal-col {
    justify-content: center;
  }
  .section-holder {
    padding-top: 50px;
    padding-bottom: 50px;
    -webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out, height 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out, height 300ms ease-in-out;
    transition: width 300ms ease-in-out, height 300ms ease-in-out;
  }
}

@media only screen and (max-width: 768px) {
  .hero-img {
    width: 60%;
  }
}
